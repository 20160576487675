import React from 'react';

import Home from './scenes/Home';

import './app.css';

const App = () => (
  <div>
    <Home />
  </div>
);

export default App;
